//
// Menu
// --------------------------------------------------


// Menu show/hide

(function ($){
  window.stickyHeaderInit = function() {
    var header = $('.region--header, .nav__toggle, .contact-info--mobile');
    var headerHeight = header.outerHeight();
    var lastScrollTop = 0;
    var scrollVar = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    var ticking = false;

    function hasScrolled() {
      var body = $('body');

      // Gotta scroll more doe
      if((lastScrollTop - scrollVar) >= -25 && (lastScrollTop - scrollVar) < 0) {
        return;
      }

      // Control header floating
      if(scrollVar > headerHeight + 25) {

        if(body.hasClass('header-float')) {
          // Control showing/hiding
          if (scrollVar > lastScrollTop){
            header.removeClass('header-down').addClass('header-up');
          } else if (scrollVar < lastScrollTop) {
            if(scrollVar + $(window).height() < $(document).height()) {
              header.removeClass('header-up').addClass('header-down');
            }
          }
        }
        else {
          body.addClass('header-float');
        }
      }
      else if(!header.hasClass('header-down')) {
        body.removeClass('header-float');
      }
      else if(scrollVar <= 5) {
        body.removeClass('header-float');
        header.removeClass('header-down header-up');
      }

      lastScrollTop = scrollVar;
    }

    function stickyHeader(){
      ticking = false;
      scrollVar = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      hasScrolled();
    }

    function requestTick() {
      if(!ticking) {
        window.requestAnimationFrame(stickyHeader);
      }
      ticking = true;
    }

    $(window).scroll(function(){
      requestTick();
    });
    requestTick();
  };


  // Mobile nav

  window.mobileNavInit = function() {

    function hideFooter(){
      $("#mobile-footer").velocity({
        opacity: 0
      });
    }

    function showFooter(){
      $("#mobile-footer").velocity({
        opacity: 1
      },{
        delay:700
      });
    }

    function adjustHeight(e) {
      $('#ml-menu').outerHeight($(e).outerHeight());
    }

    var menuEl = document.getElementById('ml-menu'), mlmenu;

    mlmenu = new MLMenu(menuEl, {
      breadcrumbsCtrl : false, // show breadcrumbs
      backCtrl : true, // show back button
      itemsDelayInterval : 90, // delay between each menu item sliding animation
    });

    // // TODO: Do this better
    $('.menu__level[data-menu="main"] .menu__link[data-submenu]').on('click',function(){
      hideFooter();
    });

    $('.menu__back').on('click',function(){
      if($('[data-depth="1"]').hasClass('menu__level--current')) {
        showFooter();
      }
    });

    adjustHeight('.menu__level');
  };
}) (jQuery);

// Do stuff

jQuery(document).ready(function($){

  // Desktops
  $(".menu-item-has-children").hoverIntent({
    over: function(){
      $(this).addClass('hover-active');
    },
    out:function(){
      $(this).removeClass('hover-active');
    },
    timeout:250
  });
});
