//Desktop Nav

(function ($){
  window.desktopNavInit = function(){

    var $desktop_nav = $('#desktop-primary-nav'),
        $main_nav    = $('.nav__main'),
        $nav_trigger = $('.nav__toggle__trigger'),
        $ease        = Power1.easeOut;
      
    function desktopNavOpen(){
      //Lock Scrolling
      $('html').addClass('lock');
      
      //opening animation
      TweenLite.set($desktop_nav, {
        autoAlpha: 0,
        y: '0%',
        onComplete: function(){
          TweenLite.to($desktop_nav, 0.5, {
            ease: $ease, 
            autoAlpha:1,
          });
          TweenLite.fromTo($main_nav, 0.75, {
            ease: $ease, 
            scale:0.8,
            autoAlpha:0,
          },
          {
            ease: $ease, 
            scale:1,
            autoAlpha:1,
          });
        }
      });  
    }

    function desktopNavClose(){
      $nav_trigger.removeClass('is-active');
      //Closing Animation
      TweenLite.fromTo($main_nav, 0.75,{
        ease: $ease, 
        scale:1,
      },
      {
        ease: $ease, 
        scale:0.8,
      });

      TweenLite.to($desktop_nav, 0.5, {
        ease: $ease, 
        autoAlpha:0,
        onComplete: function(){
          TweenLite.set($desktop_nav, {
            autoAlpha:0,
            y: '-100%'
          });      
        }
      });
      //Resume Scrolling
      $('html').removeClass('lock');
    }

    $nav_trigger.on('click', function(){
      var $window_width = parseInt($('.l-page').css('width')),
          $this         = $(this);

      if ($window_width > 1150){
        
        if(!$this.hasClass('is-active')){              
          $this.addClass('is-active');
          desktopNavOpen();            
        }else{              
          
          desktopNavClose();
        }          
      }
    });  
    
    //TODO Throttle
    $(window).resize(function(){    
      $window_width = parseInt($('.l-page').css('width'));                         
    
      if($window_width < 1150 && $nav_trigger.hasClass('is-active')){
        desktopNavClose();
      }
    });
  };
}) (jQuery);